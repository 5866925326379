/* MEGAMENU STYLE
=================================*/
nav.bootsnav .dropdown.megamenu-fw {
  position: static;
}

nav.bootsnav .container {
  position: relative;
}

nav.bootsnav .megamenu-fw .dropdown-menu {
  left: auto;
}

nav.bootsnav .megamenu-content {
  padding: 15px;
  width: 100% !important;
}

nav.bootsnav .megamenu-content .title {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--main-color);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

nav.bootsnav .dropdown.megamenu-fw .dropdown-menu {
  left: 0;
  right: 0;
}

/* Navbar
=================================*/
nav.navbar.bootsnav {
  margin-bottom: 0;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  z-index: 998;
  /*position: absolute;*/
  background: #fff;
  min-height: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

nav.navbar.bootsnav ul.nav > li > a {
  color: #212121;
  background-color: transparent;
  outline: none;
  margin-bottom: -2px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1em;
  font-size: 14px;
  font-weight: 500;
}

nav.navbar.bootsnav ul.nav li.megamenu-fw > a:hover,
nav.navbar.bootsnav ul.nav li.megamenu-fw > a:focus,
nav.navbar.bootsnav ul.nav li.active > a:hover,
nav.navbar.bootsnav ul.nav li.active > a:focus,
nav.navbar.bootsnav ul.nav li.active > a {
  background-color: transparent;
}

nav.navbar.bootsnav .navbar-toggler {
  background-color: #fff;
  border: none;
  padding: 0;
  font-size: 18px;
  position: relative;
  top: 5px;
  color: #212121;
}

nav.navbar.bootsnav ul.nav .dropdown-menu .dropdown-menu {
  top: 0;
  left: 100%;
}

nav.navbar.bootsnav ul.nav ul.dropdown-menu > li > a {
  white-space: normal;
}

.nav-onepage.navbar.bootsnav .navbar-toggle {
  background: transparent;
}

ul.menu-col {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.menu-col li a {
  color: #6f6f6f;
}

ul.menu-col li a:hover,
ul.menu-col li a:focus {
  text-decoration: none;
}

/* Navbar Full
=================================*/
nav.bootsnav.navbar-full {
  padding-bottom: 10px;
  padding-top: 10px;
}

nav.bootsnav.navbar-full .navbar-header {
  display: block;
  width: 100%;
}

nav.bootsnav.navbar-full .navbar-toggle {
  display: inline-block;
  margin-right: 0;
  position: relative;
  top: 0;
  font-size: 30px;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

nav.bootsnav.navbar-full .navbar-collapse {
  position: fixed;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  padding: 0;
  display: none !important;
  z-index: 9;
}

nav.bootsnav.navbar-full .navbar-collapse.in {
  display: block !important;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full {
  overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse .wrap-full-menu {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(30, 192, 255, 0.9);
  overflow: hidden;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-webkit-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-moz-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-ms-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-o-scrollbar {
  width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav {
  display: block;
  width: 100%;
  overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav a:hover,
nav.bootsnav.navbar-full .navbar-collapse ul.nav a:focus,
nav.bootsnav.navbar-full .navbar-collapse ul.nav a {
  background-color: transparent;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li {
  float: none;
  display: block;
  text-align: center;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li > a {
  display: table;
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 24px;
  padding: 10px 15px;
}

li.close-full-menu > a {
  padding-top: 0px;
  padding-bottom: 0px;
}

li.close-full-menu {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Atribute Navigation
=================================*/
.attr-nav {
  float: right;
  display: inline-block;
  padding-left: 13px;
  margin-top: 0px;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.attr-nav-two {
  margin-left: 20px;
  margin-top: 0px;
  height: 58px;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  float: right;
  display: inline-block;
}

.attr-nav-two li {
  list-style: none;
}

.attr-nav-two > ul > li > a.btn {
  padding: 0 30px;
  height: 35px;
  line-height: 34px;
  margin-top: 10px;
  margin-left: 20px;
}

.attr-nav > ul {
  padding: 0;
  margin: 0 0 -7px 0;
  list-style: none;
  display: inline-block;
}

.attr-nav > ul > li {
  float: left;
  display: block;
}

.attr-nav > ul > li > a {
  color: #212121;
  display: block;
  padding: 20px 15px;
  position: relative;
}

.attr-nav > ul > li > a span.badge {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 5px;
  font-size: 10px;
  padding: 0;
  width: 15px;
  height: 15px;
  padding-top: 2px;
  background-color: var(--main-color);
}

.attr-nav > ul > li > a.btn {
  padding: 0 30px;
  height: 35px;
  line-height: 34px;
  margin-top: 10px;
  margin-left: 20px;
}

.attr-nav > ul > li.dropdown ul.dropdown-menu {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  -moz-box-shadow: 0px 0px 0px;
  -webkit-box-shadow: 0px 0px 0px;
  -o-box-shadow: 0px 0px 0px;
  box-shadow: 0px 0px 0px;
  border: solid 1px #e0e0e0;
}

ul.cart-list {
  padding: 0 !important;
  width: 250px !important;
  left: -190px !important;
}

ul.cart-list > li {
  position: relative;
  border-bottom: solid 1px #efefef;
  padding: 15px 15px 23px 15px !important;
}

ul.cart-list > li > a.photo {
  padding: 0 !important;
  margin-right: 15px;
  float: left;
  display: block;
  width: 50px;
  height: 50px;
  left: 15px;
  top: 15px;
}

ul.cart-list > li img {
  width: 50px;
  height: 50px;
  border: solid 1px #efefef;
  border-radius: 4px;
}

ul.cart-list > li > h6 {
  margin: 0;
}

ul.cart-list > li > h6 a {
  color: #272625;
}

ul.cart-list > li > h6 > a.photo {
  padding: 0 !important;
  display: block;
}

ul.cart-list > li > p {
  margin-bottom: 0;
  color: #272625;
}

ul.cart-list > li.total {
  background-color: #f5f5f5;
  padding-bottom: 15px !important;
  border-radius: 0 0 15px 15px;
}

ul.cart-list > li.total > .btn {
  display: inline-block;
  border-bottom: solid 1px #efefef !important;
  height: 40px;
  border-radius: 4px;
  border-color: #efefef;
}

ul.cart-list > li.total > .btn:hover {
  color: #fff !important;
  background-color: var(--main-color) !important;
}

ul.cart-list > li .price {
  font-weight: bold;
}

ul.cart-list > li.total > span {
  padding-top: 8px;
}

/* Top Search
=================================*/
.top-search {
  background-color: #212121;
  padding: 10px 0;
  display: none;
}

.top-search input.form-control {
  background-color: transparent;
  border: none;
  -moz-box-shadow: 0px 0px 0px;
  -webkit-box-shadow: 0px 0px 0px;
  -o-box-shadow: 0px 0px 0px;
  box-shadow: 0px 0px 0px;
  color: #fff;
  height: 40px;
  padding: 0 15px;
}

.top-search .input-group-addon {
  background-color: transparent;
  border: none;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
}

.top-search .input-group-addon.close-search {
  cursor: pointer;
}

/* Side Menu
=================================*/
body {
}

body.on-side {
  margin-left: -280px;
}

.side {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: -280px;
  width: 280px;
  padding: 25px 30px;
  height: 100%;
  display: block;
  background-color: #212121;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 9;
}

.side.on {
  right: 0;
}

.side .close-side {
  float: right;
  color: #fff;
  position: relative;
  z-index: 2;
  font-size: 16px;
}

.side .widget {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}

.side .widget .title {
  color: #fff;
  margin-bottom: 15px;
}

.side .widget ul.link {
  padding: 0;
  margin: 0;
  list-style: none;
}

.side .widget ul.link li a {
  color: #fff;
  letter-spacing: 1px;
}

.side .widget ul.link li a:focus,
.side .widget ul.link li a:hover {
  color: #fff;
  text-decoration: none;
}

/* Share
=================================*/
nav.navbar.bootsnav .share {
  padding: 0 30px;
  margin-bottom: 30px;
}

nav.navbar.bootsnav .share ul {
  display: inline-block;
  padding: 0;
  margin: 0 0 -7px 0;
  list-style: none;
}

nav.navbar.bootsnav .share ul > li {
  float: left;
  display: block;
  margin-right: 5px;
}

nav.navbar.bootsnav .share ul > li > a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 35px;
  height: 35px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #cfcfcf;
  color: #fff;
}

/* Transparent
=================================*/
nav.navbar.bootsnav.navbar-fixed {
  position: fixed;
  display: block;
  width: 100%;
  /* height:60px; */
}

nav.navbar.bootsnav.no-background {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

/* Navbar Sticky
=================================*/
.wrap-sticky {
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.wrap-sticky nav.navbar.bootsnav {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.wrap-sticky nav.navbar.bootsnav.sticked {
  position: fixed;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

body.on-side .wrap-sticky nav.navbar.bootsnav.sticked {
  left: -280px;
}

/* Navbar Responsive
=================================*/
@media (min-width: 992px) and (max-width: 1400px) {
  body.wrap-nav-sidebar .wrapper .container {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  /* General Navbar
    =================================*/
  nav.navbar.bootsnav ul.nav .dropdown-menu .dropdown-menu {
    margin-top: -1px;
  }

  nav.navbar.bootsnav ul.nav.navbar-right .dropdown-menu .dropdown-menu {
    right: -200px;
  }

  nav.navbar.bootsnav ul.nav > li > a {
    padding: 20px 15px;
  }

  nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:after {
    font-family: "IcoFont";
    content: "\eab2";
    margin-left: 5px;
    margin-top: 2px;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
    -moz-border-radius: 0 0 10px 10px;
    -webkit-border-radius: 0 0 10px 10px;
    -o-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
    padding: 0;
    width: 245px;
    background: #fff;
    left: 0;
    border: 0;
    box-shadow: 0 20px 32px -8px rgba(0, 0, 0, 0.15),
      0 0 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 20px 32px -8px rgba(0, 0, 0, 0.15),
      0 0 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 20px 32px -8px rgba(0, 0, 0, 0.15),
      0 0 1px rgba(0, 0, 0, 0.05);
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover,
  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover {
    background-color: transparent;
    color: var(--main-color);
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a {
    padding: 10px 15px;
    color: #212121;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li:last-child > a {
    border-bottom: none;
  }

  nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a {
    text-align: left;
  }

  nav.navbar.bootsnav
    li.dropdown
    ul.dropdown-menu
    li.dropdown
    > a.dropdown-toggle:before {
    font-family: "IcoFont";
    float: right;
    content: "\eab8";
    margin-top: 0;
  }

  nav.navbar.bootsnav
    ul.navbar-right
    li.dropdown
    ul.dropdown-menu
    li.dropdown
    > a.dropdown-toggle:before {
    font-family: "IcoFont";
    float: right;
    content: "\eab8";
    margin-top: 0;
  }

  nav.navbar.bootsnav li.dropdown ul.dropdown-menu ul.dropdown-menu {
    top: 1px;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content {
    padding: 0 15px !important;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content > li {
    padding: 25px 0 20px;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content.tabbed {
    padding: 0;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content.tabbed > li {
    padding: 0;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu {
    padding: 0 30px;
    margin: 0 -0.5px;
    border-left: solid 1px #dddfe6;
    border-right: 1px solid #dddfe6;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu:first-child {
    border-left: none;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu:last-child {
    border-right: none;
  }

  nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content {
    display: none;
  }

  nav.navbar.bootsnav
    ul.dropdown-menu.megamenu-content
    .content
    ul.menu-col
    li
    a {
    text-align: left;
    padding: 5px 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    border-bottom: none;
    color: #272625;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }

  nav.navbar.bootsnav
    ul.dropdown-menu.megamenu-content
    .content
    ul.menu-col
    li
    a:hover {
    color: var(--main-color);
  }

  nav.navbar.bootsnav.on ul.dropdown-menu.megamenu-content .content {
    display: block !important;
    height: auto !important;
  }

  /* Navbar Transparent
    =================================*/
  nav.navbar.bootsnav.no-background {
    background-color: transparent;
    border: none;
  }

  nav.navbar.bootsnav.navbar-transparent .attr-nav {
    /*padding-left: 15px;
        margin-left: 30px;*/
  }

  nav.navbar.bootsnav.navbar-transparent.white {
    background-color: transparent;
    /*border-bottom: solid 1px #bbb;*/
    /* height:80px; */
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark,
  nav.navbar.bootsnav.navbar-transparent.dark {
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: solid 1px #555;
  }

  nav.navbar.bootsnav.navbar-transparent.white .attr-nav {
    /*border-left: solid 1px #bbb;*/
  }

  nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav,
  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav {
    border-left: solid 1px #555;
  }

  nav.navbar.bootsnav.no-background.white .attr-nav > ul > li > a,
  nav.navbar.bootsnav.navbar-transparent.white .attr-nav > ul > li > a,
  nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a,
  nav.navbar.bootsnav.no-background.white ul.nav > li > a {
    color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  nav.navbar.bootsnav.navbar-transparent.dark .attr-nav > ul > li > a,
  nav.navbar.bootsnav.navbar-transparent.dark ul.nav > li > a {
    color: #eee;
  }

  nav.navbar.bootsnav.navbar-fixed.navbar-transparent .logo-scrolled,
  nav.navbar.bootsnav.navbar-fixed.no-background .logo-scrolled {
    opacity: 0;
    height: 0;
  }

  nav.navbar.bootsnav.navbar-fixed.navbar-transparent .logo-display,
  nav.navbar.bootsnav.navbar-fixed.no-background .logo-display {
    opacity: 1;
    height: auto;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-display {
    opacity: 0;
    height: 0;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-scrolled {
    opacity: 1;
    height: auto;
  }

  /* Atribute Navigation
    =================================*/
  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    margin-top: -1px;
    margin-left: 0px;
    width: 250px;
    left: -250px;
  }

  /* Menu Center
    =================================*/
  nav.navbar.bootsnav.menu-center .container {
    position: relative;
  }

  nav.navbar.bootsnav.menu-center ul.nav.navbar-center {
    float: none;
    margin: 0 auto;
    display: table;
    table-layout: fixed;
  }

  nav.navbar.bootsnav.menu-center .navbar-header,
  nav.navbar.bootsnav.menu-center .attr-nav {
    position: absolute;
  }

  nav.navbar.bootsnav.menu-center .attr-nav {
    right: 15px;
  }

  /* Navbar Brand top
    =================================*/
  nav.bootsnav.navbar-brand-top .navbar-header {
    display: block;
    width: 100%;
    text-align: center;
  }

  nav.bootsnav.navbar-brand-top ul.nav > li.dropdown > ul.dropdown-menu {
    margin-top: 2px;
  }

  nav.bootsnav.navbar-brand-top
    ul.nav
    > li.dropdown.megamenu-fw
    > ul.dropdown-menu {
    margin-top: 0;
  }

  nav.bootsnav.navbar-brand-top .navbar-header .navbar-brand {
    display: inline-block;
    float: none;
    margin: 0;
  }

  nav.bootsnav.navbar-brand-top .navbar-collapse {
    text-align: center;
  }

  nav.bootsnav.navbar-brand-top ul.nav {
    display: inline-block;
    float: none;
    margin: 0 0 -5px 0;
  }

  /* Navbar Center
    =================================*/
  nav.bootsnav.brand-center .navbar-header {
    display: block;
    width: 100%;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
  }

  nav.bootsnav.brand-center .navbar-brand {
    display: inline-block;
    float: none;
  }

  nav.bootsnav.brand-center .navbar-collapse {
    text-align: center;
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }

  nav.bootsnav.brand-center ul.nav > li.dropdown > ul.dropdown-menu {
    margin-top: 2px;
  }

  nav.bootsnav.brand-center
    ul.nav
    > li.dropdown.megamenu-fw
    > ul.dropdown-menu {
    margin-top: 0;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half {
    width: 50%;
    float: left;
    display: block;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half.left {
    text-align: right;
    padding-right: 100px;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half.right {
    text-align: left;
    padding-left: 100px;
  }

  nav.bootsnav.brand-center ul.nav {
    float: none !important;
    margin-bottom: -5px !important;
    display: inline-block !important;
  }

  nav.bootsnav.brand-center ul.nav.navbar-right {
    margin: 0;
  }

  nav.bootsnav.brand-center.center-side .navbar-collapse .col-half.left {
    text-align: left;
    padding-right: 100px;
  }

  nav.bootsnav.brand-center.center-side .navbar-collapse .col-half.right {
    text-align: right;
    padding-left: 100px;
  }

  /* Navbar Sidebar
    =================================*/
  body.wrap-nav-sidebar .wrapper {
    padding-left: 260px;
    overflow-x: hidden;
  }

  nav.bootsnav.navbar-sidebar {
    position: fixed;
    width: 260px;
    overflow: hidden;
    left: 0;
    padding: 0 0 0 0 !important;
    background: #fff;
    border-right: solid 1px #f1f1f1;
  }

  nav.bootsnav.navbar-sidebar .scroller {
    width: 280px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  nav.bootsnav.navbar-sidebar .container-fluid,
  nav.bootsnav.navbar-sidebar .container {
    padding: 0 !important;
  }

  nav.bootsnav.navbar-sidebar .navbar-header {
    float: none;
    display: block;
    width: 260px;
    padding: 10px 50px;
    margin: 10px 0 40px 0 !important;
  }

  nav.bootsnav.navbar-sidebar .navbar-collapse {
    padding: 0 !important;
    width: 260px;
  }

  nav.bootsnav.navbar-sidebar ul.nav {
    float: none;
    display: block;
    width: 100%;
    padding: 0 15px !important;
    margin: 0 0 30px 0;
  }

  nav.bootsnav.navbar-sidebar ul.nav li {
    float: none !important;
    margin-bottom: 15px;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li > a {
    padding: 10px 15px;
    font-weight: bold;
    text-align: center;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li > a:hover {
    color: var(--main-color);
  }

  nav.bootsnav.navbar-sidebar ul.nav > li.dropdown > a:after {
    float: right;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu {
    left: 100%;
    top: 0;
    position: relative !important;
    left: 0 !important;
    width: 100% !important;
    height: auto !important;
    background-color: transparent;
    border: none !important;
    padding: 0;
    -moz-box-shadow: 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 0px;
    -o-box-shadow: 0px 0px 0px;
    box-shadow: 0px 0px 0px;
  }

  nav.bootsnav.navbar-sidebar ul.nav .megamenu-content .col-menu {
    border: none !important;
  }

  nav.bootsnav.navbar-sidebar ul.nav > li.dropdown > ul.dropdown-menu {
    margin-bottom: 15px;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu {
    padding-left: 0;
    float: none;
    margin-bottom: 0;
  }

  nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu li a {
    padding: 5px 15px;
    color: #6f6f6f;
    border: none;
  }

  nav.bootsnav.navbar-sidebar
    ul.nav
    li.dropdown
    ul.dropdown-menu
    ul.dropdown-menu {
    padding-left: 15px;
    margin-top: 0;
  }

  nav.bootsnav.navbar-sidebar
    ul.nav
    li.dropdown
    ul.dropdown-menu
    li.dropdown
    > a:before {
    font-family: "IcoFont";
    content: "\eab8";
    float: right;
  }

  nav.bootsnav.navbar-sidebar
    ul.nav
    li.dropdown.on
    ul.dropdown-menu
    li.dropdown.on
    > a:before {
    content: "\eab2";
  }

  nav.bootsnav.navbar-sidebar ul.dropdown-menu.megamenu-content > li {
    padding: 0 !important;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu {
    display: block;
    float: none !important;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .title {
    padding: 7px 0;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0px;
    margin-bottom: 0;
    cursor: pointer;
    color: #6f6f6f;
  }

  nav.bootsnav.navbar-sidebar
    .dropdown
    .megamenu-content
    .col-menu
    .title:before {
    font-family: "IcoFont";
    content: "\eab8";
    float: right;
  }

  nav.bootsnav.navbar-sidebar
    .dropdown
    .megamenu-content
    .col-menu.on
    .title:before {
    content: "\eab2";
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu {
    border: none;
  }

  nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .content {
    padding: 0 0 0 15px;
  }

  nav.bootsnav.navbar-sidebar
    .dropdown
    .megamenu-content
    .col-menu
    ul.menu-col
    li
    a {
    padding: 3px 0 !important;
  }
}

@media (max-width: 992px) {
  /* Navbar Responsive
    =================================*/
  nav.navbar.bootsnav .navbar-brand {
    display: inline-block;
    float: none !important;
    margin: 0 !important;
  }

  nav.navbar.bootsnav .navbar-header {
    float: none;
    display: block;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  nav.navbar.bootsnav .navbar-toggler {
    display: inline-block;
    float: left;
    margin-right: -200px;
    margin-top: 17px;
  }

  nav.navbar.bootsnav .navbar-collapse {
    border: none;
    margin-bottom: 0;
  }

  nav.navbar.bootsnav.no-full .navbar-collapse {
    /* max-height: 350px; */
    /* overflow-y: auto !important; */
  }

  nav.navbar.bootsnav .navbar-collapse.collapse {
    display: none !important;
  }

  nav.navbar.bootsnav .navbar-collapse.collapse.in {
    display: block !important;
  }

  nav.navbar.bootsnav .navbar-nav {
    float: none !important;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0px -15px;
  }

  nav.navbar.bootsnav .navbar-nav > li {
    float: none;
  }

  nav.navbar.bootsnav li.dropdown a.dropdown-toggle:before {
    font-family: "IcoFont";
    content: "\eab8";
    float: right;
    font-size: 16px;
    margin-left: 10px;
  }

  nav.navbar.bootsnav li.dropdown.on > a.dropdown-toggle:before {
    content: "\eab2";
  }

  nav.navbar.bootsnav .navbar-nav > li > a {
    display: block;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 10px 0;
    border-top: solid 1px #e0e0e0;
    margin-bottom: -1px;
  }

  nav.navbar.bootsnav .navbar-nav > li:first-child > a {
    border-top: none;
  }

  nav.navbar.bootsnav
    ul.navbar-nav.navbar-left
    > li:last-child
    > ul.dropdown-menu {
    border-bottom: solid 1px #e0e0e0;
  }

  nav.navbar.bootsnav ul.nav li.dropdown li a.dropdown-toggle {
    float: none !important;
    position: relative;
    display: block;
    width: 100%;
  }

  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu {
    width: 100%;
    position: relative !important;
    background-color: transparent;
    float: none;
    border: none;
    padding: 0 0 0 15px !important;
    margin: 0 0 -1px 0 !important;
    -moz-box-shadow: 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 0px;
    -o-box-shadow: 0px 0px 0px;
    box-shadow: 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px;
    -webkit-border-radius: 0px 0px 0px;
    -o-border-radius: 0px 0px 0px;
    border-radius: 0px 0px 0px;
  }

  nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu > li > a {
    display: block;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 10px 0;
    color: #6f6f6f;
  }

  nav.navbar.bootsnav ul.nav ul.dropdown-menu li a:hover,
  nav.navbar.bootsnav ul.nav ul.dropdown-menu li a:focus {
    background-color: transparent;
  }

  nav.navbar.bootsnav ul.nav ul.dropdown-menu ul.dropdown-menu {
    float: none !important;
    left: 0;
    padding: 0 0 0 15px;
    position: relative;
    background: transparent;
    width: 100%;
  }

  nav.navbar.bootsnav
    ul.nav
    ul.dropdown-menu
    li.dropdown.on
    > ul.dropdown-menu {
    display: inline-block;
    margin-top: -10px;
  }

  nav.navbar.bootsnav
    li.dropdown
    ul.dropdown-menu
    li.dropdown
    > a.dropdown-toggle:after {
    display: none;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .title {
    padding: 10px 15px 10px 0;
    line-height: 24px;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0px;
    margin-bottom: 0;
    cursor: pointer;
    border-bottom: solid 1px #e0e0e0;
    color: #6f6f6f;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu ul > li > a {
    display: block;
    width: 100%;
    border-bottom: solid 1px #e0e0e0;
    padding: 8px 0;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .title:before {
    font-family: "IcoFont";
    content: "\eab8";
    float: right;
    font-size: 16px;
    margin-left: 10px;
    position: relative;
    right: -15px;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu:last-child .title {
    border-bottom: none;
  }

  nav.navbar.bootsnav
    .dropdown
    .megamenu-content
    .col-menu.on:last-child
    .title {
    border-bottom: solid 1px #e0e0e0;
  }

  nav.navbar.bootsnav
    .dropdown
    .megamenu-content
    .col-menu:last-child
    ul.menu-col
    li:last-child
    a {
    border-bottom: none;
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu.on .title:before {
    content: "\eab2";
  }

  nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .content {
    padding: 0 0 0 15px;
  }

  nav.bootsnav.brand-center .navbar-collapse {
    display: block;
  }

  nav.bootsnav.brand-center ul.nav {
    margin-bottom: 0px !important;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half {
    width: 100%;
    float: none;
    display: block;
  }

  nav.bootsnav.brand-center .navbar-collapse .col-half.left {
    margin-bottom: 0;
  }

  nav.bootsnav .megamenu-content {
    padding: 0;
  }

  nav.bootsnav .megamenu-content .col-menu {
    padding-bottom: 0;
  }

  nav.bootsnav .megamenu-content .title {
    cursor: pointer;
    display: block;
    padding: 10px 15px;
    margin-bottom: 0;
    font-weight: normal;
  }

  nav.bootsnav .megamenu-content .content {
    display: none;
  }

  .attr-nav {
    position: absolute;
    right: 60px;
  }

  .attr-nav > ul {
    padding: 0;
    margin: 0 -15px -7px 0;
  }

  .attr-nav > ul > li > a {
    /*padding: 16px 15px 15px;*/
  }

  .attr-nav > ul > li.dropdown > a.dropdown-toggle:before {
    display: none;
  }

  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    margin-top: 2px;
    margin-left: 55px;
    width: 250px;
    left: -250px;
    border-top: solid 5px;
  }

  .top-search .container {
    padding: 0 45px;
  }

  /* Navbar full Responsive
    =================================*/
  nav.bootsnav.navbar-full ul.nav {
    margin-left: 0;
  }

  nav.bootsnav.navbar-full ul.nav > li > a {
    border: none;
  }

  nav.bootsnav.navbar-full .navbar-brand {
    float: left !important;
    padding-left: 0;
  }

  nav.bootsnav.navbar-full .navbar-toggle {
    display: inline-block;
    float: right;
    margin-right: 0;
    margin-top: 10px;
  }

  nav.bootsnav.navbar-full .navbar-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  /* Navbar Sidebar
    =================================*/
  nav.navbar.bootsnav.navbar-sidebar .share {
    padding: 30px 15px;
    margin-bottom: 0;
  }

  /* Tabs
    =================================*/
  nav.navbar.bootsnav .megamenu-content.tabbed {
    padding-left: 0 !important;
  }

  nav.navbar.bootsnav .tabbed > li {
    padding: 25px 0;
    margin-left: -15px !important;
  }

  /* Mobile Navigation
    =================================*/
  body > .wrapper {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  body.side-right > .wrapper {
    margin-left: 280px;
    margin-right: -280px !important;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse {
    position: fixed;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    display: block;
    background: #fff;
    z-index: 99;
    width: 280px;
    height: 100% !important;
    left: -280px;
    top: 0;
    padding: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse.in {
    left: 0;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav {
    width: 293px;
    padding-right: 0;
    padding-left: 15px;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav > li > a {
    padding: 15px 15px;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu > li > a {
    padding-right: 15px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title {
    padding-right: 30px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar.bootsnav.navbar-mobile
    ul.nav
    ul.dropdown-menu
    .col-menu
    ul.menu-col
    li
    a {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse [class*=" col-"] {
    width: 100%;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-scrolled {
    display: block !important;
    margin-bottom: 15px;
    margin-left: 20px;
  }

  nav.navbar.bootsnav.navbar-fixed .logo-display {
    display: none !important;
  }

  nav.navbar.bootsnav.navbar-mobile .tab-menu,
  nav.navbar.bootsnav.navbar-mobile .tab-content {
    width: 100%;
    display: block;
  }
}

@media (max-width: 767px) {
  nav.navbar.bootsnav .navbar-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  nav.navbar.bootsnav .navbar-nav {
    padding-left: 15px;
    padding-right: 15px;
  }

  .attr-nav {
    right: 30px;
  }

  .attr-nav > ul {
    margin-right: -10px;
  }

  .attr-nav > ul > li > a {
    /*padding: 16px 10px 15px;*/
    padding-left: 0 !important;
  }

  .attr-nav > ul > li.dropdown ul.dropdown-menu {
    left: -275px;
  }

  .top-search .container {
    padding: 0 15px;
  }

  nav.bootsnav.navbar-full .navbar-collapse {
    left: 15px;
  }

  nav.bootsnav.navbar-full .navbar-header {
    padding-right: 0;
  }

  nav.bootsnav.navbar-full .navbar-toggle {
    margin-right: -15px;
  }

  nav.bootsnav.navbar-full ul.nav > li > a {
    font-size: 18px !important;
    line-height: 24px !important;
    padding: 5px 10px !important;
  }

  /* Navbar Sidebar
    =================================*/
  nav.navbar.bootsnav.navbar-sidebar .share {
    padding: 30px 15px !important;
  }

  /* Navbar Sidebar
    =================================*/
  nav.navbar.bootsnav.navbar-sidebar .share {
    padding: 30px 0 !important;
    margin-bottom: 0;
  }

  nav.navbar.bootsnav.navbar-mobile.navbar-sidebar .share {
    padding: 30px 15px !important;
    margin-bottom: 0;
  }

  /* Mobile Navigation
    =================================*/
  body.side-right > .wrapper {
    margin-left: 280px;
    margin-right: -280px !important;
  }

  nav.navbar.bootsnav.navbar-mobile .navbar-collapse {
    margin-left: 0;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav {
    margin-left: -15px;
  }

  nav.navbar.bootsnav.navbar-mobile ul.nav {
    border-top: solid 1px #fff;
  }

  li.close-full-menu {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}

@media (min-width: 480px) and (max-width: 640px) {
  nav.bootsnav.navbar-full ul.nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.nav-scrollspy-onepage {
}

.nav-scrollspy-onepage a:hover {
  color: var(--main-color) !important;
}

.nav-scrollspy-onepage .active a {
  color: var(--main-color) !important;
}

.red-nav.nav-scrollspy-onepage {
}

.red-nav.nav-scrollspy-onepage a:hover {
  color: #c88012 !important;
}

.red-nav.nav-scrollspy-onepage .active a {
  color: #c88012 !important;
}

.yellow-nav.nav-scrollspy-onepage {
}

.yellow-nav.nav-scrollspy-onepage a:hover {
  color: #f0ad4e !important;
}

.yellow-nav.nav-scrollspy-onepage .active a {
  color: #f0ad4e !important;
}

.green-nav.nav-scrollspy-onepage a:hover {
  color: #00c07f !important;
}

.green-nav.nav-scrollspy-onepage .active a {
  color: #00c07f !important;
}

/* Search Overlay */

.fullscreen-search-overlay
  #fullscreen-search-wrapper
  #fullscreen-searchform
  input[type="submit"] {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  right: 0;
  top: 0;
}
#fullscreen-searchform:focus {
  outline: none;
  border: none;
}

.fullscreen-search-overlay
  #fullscreen-search-wrapper
  #fullscreen-searchform
  .fullscreen-search-icon {
  font-size: 25px;
  position: absolute;
  right: 0px;
  width: 25px;
  top: 30px;
  color: #fff;
  color: rgba(255, 255, 255, 0.2);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.fullscreen-search-icon:hover,
.fullscreen-search-icon:focus {
  border: none;
  outline: none;
  color: #fff !important;
}

#fullscreen-searchform {
  position: relative;
  verticle-align: middle;
}

.fullscreen-search-overlay
  #fullscreen-search-wrapper
  #fullscreen-searchform
  #fullscreen-search-input {
  min-width: 1170px;
  background-color: transparent;
  border: 0;
  text-align: left;
  font-size: 35px;
  padding: 20px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.fullscreen-search-overlay
  #fullscreen-search-wrapper
  #fullscreen-searchform
  #fullscreen-search-input:focus {
  border: none;
  outline: none;
}

.fullscreen-search-overlay.fullscreen-search-overlay-show {
  visibility: visible;
  opacity: 1;
}

.fullscreen-search-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(33, 33, 33, 0.98);
  text-align: center;
  -webkit-transition: all ease-in-out 0.25s;
  -moz-transition: all ease-in-out 0.25s;
  -ms-transition: all ease-in-out 0.25s;
  -o-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
}

.animate-element,
.effect-bg-layer,
.fullscreen-search-overlay {
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.fullscreen-search-overlay {
  z-index: 999;
}

.fullscreen-search-overlay .fullscreen-close:link,
.fullscreen-search-overlay .fullscreen-close:visited {
  color: #fff;
}

.fullscreen-search-overlay .fullscreen-close {
  position: absolute;
  right: 50px;
  top: 70px;
  font-size: 26px;
  -webkit-transition: transform ease-out 0.2s;
  -moz-transition: transform ease-out 0.2s;
  -ms-transition: transform ease-out 0.2s;
  -o-transition: transform ease-out 0.2s;
  transition: transform ease-out 0.2s;
  transform: rotate(0deg);
}

.fullscreen-search-overlay.fullscreen-search-overlay-show
  #fullscreen-search-wrapper {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  ms-transform: scale(1);
}

.fullscreen-search-overlay #fullscreen-search-wrapper {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  ms-transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.search-trigger {
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

#search-button {
  font-size: 16px;
}

#search-button:hover {
  opacity: 0.6;
}

.fullscreen-close:hover {
  color: var(--main-color) !important;
}

#fullscreen-search-input:focus {
  box-shadow: 0px 3px 0px 0px rgba(33, 150, 243, 1) !important;
}

#fullscreen-search-input:hover {
  box-shadow: 0px 3px 0px 0px rgba(255, 255, 255, 1) !important;
}
